// Generated by Framer (3d7e2b6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./CIbn8DASH-0.js";

const cycleOrder = ["nIu_Wkk5Q", "Klg4TWV7o"];

const serializationHash = "framer-jY4iU"

const variantClassNames = {Klg4TWV7o: "framer-v-mkat2g", nIu_Wkk5Q: "framer-v-1efu9dl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, Klg4TWV7o: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "nIu_Wkk5Q", "Variant 2": "Klg4TWV7o"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, RKrj24Ddj: image ?? props.RKrj24Ddj ?? {src: "https://framerusercontent.com/images/mLvEUBA6mZxvereSsxIU5H3jE.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/mLvEUBA6mZxvereSsxIU5H3jE.png?scale-down-to=512 401w, https://framerusercontent.com/images/mLvEUBA6mZxvereSsxIU5H3jE.png 448w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "nIu_Wkk5Q"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, RKrj24Ddj, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "nIu_Wkk5Q", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEntergnqhaj = activeVariantCallback(async (...args) => {
setVariant(CycleVariantState)
})

const onMouseLeave1ogwb0q = activeVariantCallback(async (...args) => {
setVariant("nIu_Wkk5Q")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-jY4iU", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 572, intrinsicWidth: 448, pixelHeight: 572, pixelWidth: 448, sizes: "min(211px, 100vw)", ...toResponsiveImage(RKrj24Ddj)}} className={cx("framer-1efu9dl", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"nIu_Wkk5Q"} onMouseEnter={onMouseEntergnqhaj} ref={ref ?? ref1} style={{filter: "none", WebkitFilter: "none", ...style}} tabIndex={2} variants={{Klg4TWV7o: {filter: "hue-rotate(360deg) drop-shadow(0px 0px 22px hsla(0, 0%, 100%, 0.25))", WebkitFilter: "hue-rotate(360deg) drop-shadow(0px 0px 22px hsla(0, 0%, 100%, 0.25))"}}} {...addPropertyOverrides({Klg4TWV7o: {"data-framer-name": "Variant 2", onMouseEnter: undefined, onMouseLeave: onMouseLeave1ogwb0q}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-jY4iU [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jY4iU .framer-iu7b44 { display: block; }", ".framer-jY4iU .framer-1efu9dl { height: 269px; position: relative; width: 211px; }", ".framer-jY4iU.framer-v-mkat2g .framer-1efu9dl { aspect-ratio: 0.7832167832167832 / 1; height: var(--framer-aspect-ratio-supported, 269px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 269
 * @framerIntrinsicWidth 211
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Klg4TWV7o":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"RKrj24Ddj":"image"}
 * @framerImmutableVariables false
 */
const FramerCIbn8DASH: React.ComponentType<Props> = withCSS(Component, css, "framer-jY4iU") as typeof Component;
export default FramerCIbn8DASH;

FramerCIbn8DASH.displayName = "Žiarovka";

FramerCIbn8DASH.defaultProps = {height: 269, width: 211};

addPropertyControls(FramerCIbn8DASH, {variant: {options: ["nIu_Wkk5Q", "Klg4TWV7o"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, RKrj24Ddj: {__defaultAssetReference: "data:framer/asset-reference,mLvEUBA6mZxvereSsxIU5H3jE.png?originalFilename=0bad33c56d2243be93b05cb09f29d058.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerCIbn8DASH, [])